import React from "react";

import "./Welcome.css";

export default function Welcome() {
    return (
        <div className="welcome-container">
            You Will Be Redirected to www.NitinJayant.com
        </div>
    );
}
